.spaia {
  .mat-drawer,
  .mat-mdc-toolbar,
  .mat-card,
  .mat-mdc-drawer-container,
  .mat-mdc-expansion-panel {
    background-color: #0c2464;
  }
  .mat-sidenav-content {
    background-color: #f0f0f0;
  }
  .mat-sidenav {
    background-color: #0c2464;
    .mat-mdc-button,
    .mat-mdc-button .mdc-button__label,
    .mdc-button__label {
      color: #fff !important;
    }
    .mat-toolbar {
      background-color: #3cf4a2;
    }
  }
  .mat-mdc-expansion-panel-header::before,
  .mat-mdc-subheader::before {
    content: "";
    margin: 5px 0;
    border-left: 5px solid #4b4b4b99;
    display: block;
    position: absolute;
    height: 18px;
    left: 0;
  }
  .mat-mdc-expansion-panel::after {
    content: "";
    margin: 0 20px;
    display: block;
    border-bottom: 1px dashed #4b4b4b99;
  }
  mat-card-title {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .mat-mdc-divider {
    margin: 0 10px;
  }

  .mat-mdc-ink-bar {
    background-color: rgb(75, 75, 75) !important;
  }
  .mdc-tab__text-label {
    padding: 1rem 0;
  }
  [mat-mdc-align-tabs="center"] > .mat-mdc-tab-header .mat-mdc-tab-labels {
    justify-content: space-evenly !important;
    .mat-mdc-tab-label {
      padding: 0 !important;
      min-width: 60px !important;
      flex-shrink: 0;
      flex-grow: 1;
      &.mat-mdc-tab-label-active {
        background-color: rgb(75, 75, 75);
        color: #fff;
      }
    }
  }
  .mat-mdc-toolbar {
    h4 {
      font-weight: 200;
      font-size: 1rem;
    }

    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 15px;
      width: 100%;
      top: calc(100% - 15px);
      left: 0;
      background: repeating-linear-gradient(
        135deg,
        transparent,
        transparent 4px,
        #666 6px,
        #666 6px
      );
    }
  }
  .mat-mdc-button {
    font-size: 0.8rem;
    line-height: 1.4rem;
    font-weight: 500;
    background-color: transparent;
  }
  .mat-mdc-raised-button {
    border-radius: 1.4rem;
    font-size: 0.8rem;
    line-height: 1.4rem;
    font-weight: 500;

    border: 1px solid #0c2464;
    color: #0c2464;
    padding: 0 22px;
    &.mat-mdc-primary {
      color: #fff;
      &.mat-mdc-button-disabled {
        color: rgba(0, 0, 0, 0.12);
        border: 2px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  app-nav .mat-mdc-list-base .mat-mdc-list-item,
  app-nav .mat-mdc-list-base .mat-mdc-list-option {
    height: 1.5rem;
  }
  .mat-mdc-dialog-title {
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-weight: 300;
    margin: 0;
  }
}
