@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body {
  display: block;
  height: 100%;
}
@import "material-icons/iconfont/material-icons.css";
@font-face {
  font-family: MuseoSans;
  src: url("assets/fonts/MuseoSans_100.otf");
  font-weight: 100;
}

@font-face {
  font-family: MuseoSans;
  src: url("assets/fonts/MuseoSans_300.otf");
  font-weight: 300;
}

@font-face {
  font-family: MuseoSans;
  src: url("assets/fonts/MuseoSans_500.otf");
  font-weight: 500;
}

@font-face {
  font-family: MuseoSans;
  src: url("assets/fonts/MuseoSans_700.otf");
  font-weight: 700;
}
body {
  margin: 0;
  font-family: MuseoSans, "Helvetica Neue", sans-serif;
}

.container {
  padding: 25px;
}
.spacer {
  flex: 1 1 auto;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
